<template>
  <div style="margin: -10px -25px -10px -25px">
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="计划年份" prop="year" style="width: 25%">
              <a-select v-model="queryParams.year">
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="是否作废" prop="status" style="width: 25%">
              <a-select v-model="queryParams.status">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">否</a-select-option>
                <a-select-option value="1">是</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-button v-if="btnList.includes('复制')" @click="yearPlanCopy" style="margin-right: 10px"><a-icon type="snippets"></a-icon>年度计划复制</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="state_id" :loading="tableLoading" :scroll="{ x: 2800}" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="confirm_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="is_confirmed" slot-scope="value">
            <span>{{value==='1'?'已确认':'未确认'}}</span>
          </span>
          <span slot="status" slot-scope="value">
            <span>{{value==='0'?'否':'是'}}</span>
          </span>
          <span slot="income_type" slot-scope="value">{{expendType[value]}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.state_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')&&record.year!==currentYear.toString()&&record.is_confirmed!=='1'" :key="'edit-'+record.state_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('确认')&&record.is_confirmed!=='1'" :key="'confirm-'+record.state_id">确认</a-menu-item>
                <a-menu-item v-if="btnList.includes('作废')&&record.status!=='1'" :key="'delete-'+record.state_id">作废</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <h3 style="color:red">注意：未确认的年度计划条目，将不会在月度计划中显示</h3>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="planModalVisible" title="年度计划复制" :width="400" :centered="true" @cancel="planModalVisible=false">
      <template slot="footer">
        <a-button @click="planModalVisible=false">关闭</a-button>
        <a-button @click="generateYearPlan" type="primary">保存</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划来源年份" prop="sourceYear">
          <a-select v-model="formData.sourceYear" placeholder="请选择">
            <a-select-option :value="moment().year()-1">{{moment().year()-1}}</a-select-option>
            <a-select-option :value="moment().year()">{{moment().year()}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="目标年份" prop="targetYear">
          <a-select v-model="formData.targetYear" placeholder="请选择">
            <a-select-option :value="moment().year()">{{moment().year()}}</a-select-option>
            <a-select-option :value="moment().year()+1">{{moment().year()+1}}</a-select-option>
            <a-select-option :value="moment().year()+2">{{moment().year()+2}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData(false)"></add-or-edit-modal>
  </div>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/reportForms/operatingStatements/year/expend/addOrEditModal";
import {
  confirmOperatingStatement,
  deleteOperatingStatement, generateOperationYearPlan,
  getOperatingStatementListByCondition, modifyOperatingStatement,
} from "A/businessmanagement";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {expendType} from "@/json/businessmanagement";
export default {
  name: "expend",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      currentYear: new Date().getFullYear(),
      pageTitle:'年度支出计划',
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      expendType,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        category:'年度支出计划',
        finance_type:'支出',
        decision_type:'计划',
        year:'',
        status:'0',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '支出类型',
          dataIndex: 'income_type',
          key: 'income_type',
          ellipsis: true,
          scopedSlots: { customRender: 'income_type' },
        },
        {
          title: '是否作废',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '确认状态',
          dataIndex: 'is_confirmed',
          key: 'is_confirmed',
          ellipsis: true,
          scopedSlots: { customRender: 'is_confirmed' },
        },
        {
          title: '确认时间',
          dataIndex: 'confirm_time',
          key: 'confirm_time',
          ellipsis: true,
          scopedSlots: { customRender: 'confirm_time' },
        },
        {
          title: '确认人',
          dataIndex: 'confirm_man',
          key: 'confirm_man',
          ellipsis: true,
        },
        {
          title: '一月',
          dataIndex: 'month_one',
          key: 'month_one',
          ellipsis: true,
        },
        {
          title: '二月',
          dataIndex: 'month_two',
          key: 'month_two',
          ellipsis: true,
        },
        {
          title: '三月',
          dataIndex: 'month_three',
          key: 'month_three',
          ellipsis: true,
        },
        {
          title: '四月',
          dataIndex: 'month_four',
          key: 'month_four',
          ellipsis: true,
        },
        {
          title: '五月',
          dataIndex: 'month_five',
          key: 'month_five',
          ellipsis: true,
        },
        {
          title: '六月',
          dataIndex: 'month_six',
          key: 'month_six',
          ellipsis: true,
        },
        {
          title: '七月',
          dataIndex: 'month_seven',
          key: 'month_seven',
          ellipsis: true,
        },
        {
          title: '八月',
          dataIndex: 'month_eight',
          key: 'month_eight',
          ellipsis: true,
        },
        {
          title: '九月',
          dataIndex: 'month_nine',
          key: 'month_nine',
          ellipsis: true,
        },
        {
          title: '十月',
          dataIndex: 'month_ten',
          key: 'month_ten',
          ellipsis: true,
        },
        {
          title: '十一月',
          dataIndex: 'month_eleven',
          key: 'month_eleven',
          ellipsis: true,
        },
        {
          title: '十二月',
          dataIndex: 'month_twelve',
          key: 'month_twelve',
          ellipsis: true,
        },
        {
          title: '计划年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      userdepidCascaderSelected: [],
      yearOptions: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      monitorpointList:[],
      planModalVisible:false,
      formData:{
        monitorpointnum:'',
        sourceYear:'',
        targetYear:'',
        category:'年度支出计划',
        finance_type:'支出',
        decision_type:'计划',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        sourceYear: [{required: true, message: '请选择计划来源年份'}],
        targetYear: [{required: true, message: '请选择目标年份'}],
      },

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getMonitorPointNameList()
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getOperatingStatementListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let state_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'state_id', state_id);
      if(type == 'delete') {
        this.deleteConfirm(state_id, record)
      }else if(type == 'confirm'){
        this.confirmOperatingStatement(state_id)
      }else{
        this.showModal(type,record)
      }
    },
    confirmOperatingStatement(state_id){
      this.$confirm('您要确认该计划吗？计划确认后将无法再进行修改！',{
        type:'warning',
        centered:true,
      }).then(()=>{
        confirmOperatingStatement({state_id:state_id,is_confirmed:'1',confirm_time:moment(new Date()).format("YYYYMMDDHHmmss"),confirm_man:this.userInfo.username}).then(res=>{
          if(res&&res.returncode=='0'){
            this.getTableData()
            this.$message.success("确认成功")
          }
        })
      }).catch(()=>{
      });
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEquipDirectories()
      }).catch(()=>{
      });
    },
    exportEquipDirectories(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportOperatingStatementExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要作废该数据吗?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.state_id);
      }).catch(()=>{

      }) ;
    },
    delete(state_id) {
      this.showLoading();
      if(state_id) {
        let params = {
          state_id
        };
        deleteOperatingStatement(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let lastYear = moment().year()-1;
      for(let i=1; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
      this.yearOptions.push(lastYear+'')
      this.queryParams.year=currentYear+''
    },
    yearPlanCopy(){
      this.planModalVisible=true
    },
    generateYearPlan(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          generateOperationYearPlan({...this.formData})
          this.planModalVisible=false
          this.$message.success("内容已提交后台，正在处理中，请稍后再进行查看")
          this.formData={
            monitorpointnum:'',
            sourceYear:'',
            targetYear:'',
            category:'年度支出计划',
            finance_type:'支出',
            decision_type:'计划',
          }
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style scoped>

</style>