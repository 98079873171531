export const workOrderStatus = {
  '1': '进行中',
  '2': '已完成',
}

export const workOrderPicType = {
  '11': '作业前图片',
  '12': '作业后图片',
  '16': '作业视频',
  '17': '巡查签名',
}

export const orderException = {
  "0": "正常",
  "1": "异常",
}
export const orderExceptionColors = {
  "0": "green",
  "1": "red",
}

export const workType = {
  '清洁': '0001',
  '绿化': '0002',
  '消杀': '0003',
  '白蚁防治': '0004',
}

export const incomeType = {
  '1': '物业管理费',
  '2': '车位管理费',
  '3': '产权车场收入',
  '4': '公共收入',
  '5': '多经收入',
}

export const expendType = {
  '1': '物业服务支出',
  '2': '人工费用',
  '3': '公共设施设备维保费',
  '4': '公共能耗费',
  '5': '清洁费',
  '6': '绿化养护费',
  '7': '其它费用',
  '8': '公共收入成本费',
  '9': '多经业务成本费',
}

export const energyType = {
  '1': '公共用电',
  '2': '电梯用电',
  '3': '二次供水用电',
  '4': '车库照明用电',
  '5': '中央空调用电',
  '6': '服务中心办公用电',
  '7': '公共用水',
  '8': '园区用水',
  '9': '车库清洁用水',
  '10': '中央空调用水',
  '11': '燃气用量',
  '12': '商户用电',
  '13': '住户用电',
  '14': '住户用水',
  '15': '其它能耗',
}