<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="收入类型" prop="income_type">
          <a-select :disabled="showType=='detail'" v-model="formData.income_type">
            <a-select-option value="1">物业管理费</a-select-option>
            <a-select-option value="2">车位管理费</a-select-option>
            <a-select-option value="3">产权车场收入</a-select-option>
            <a-select-option value="4">公共收入</a-select-option>
            <a-select-option value="5">多经收入</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划年份" prop="year">
          <a-select :disabled="showType=='detail'" v-model="formData.year">
            <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="" prop=""></a-form-model-item>
        <a-form-model-item label="一月" prop="month_one">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_one" @blur="formData.month_one=formData.month_one.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="二月" prop="month_two">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_two" @blur="formData.month_two=formData.month_two.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="三月" prop="month_three">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_three" @blur="formData.month_three=formData.month_three.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="四月" prop="month_four">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_four" @blur="formData.month_four=formData.month_four.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="五月" prop="month_five">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_five" @blur="formData.month_five=formData.month_five.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="六月" prop="month_six">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_six" @blur="formData.month_six=formData.month_six.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="七月" prop="month_seven">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_seven" @blur="formData.month_seven=formData.month_seven.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="八月" prop="month_eight">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_eight" @blur="formData.month_eight=formData.month_eight.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="九月" prop="month_nine">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_nine" @blur="formData.month_nine=formData.month_nine.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十月" prop="month_ten">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_ten" @blur="formData.month_ten=formData.month_ten.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十一月" prop="month_eleven">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_eleven" @blur="formData.month_eleven=formData.month_eleven.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十二月" prop="month_twelve">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_twelve" @blur="formData.month_twelve=formData.month_twelve.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addOperatingStatement,
  getOperatingStatementListByCondition, getWorkPointListByCondition, getWorkUnitsList, getWorkUnitsListByCondition,
  modifyOperatingStatement
} from "A/businessmanagement";
import {getCache} from "U/index";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '450px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum:'',
        category:'年度收入计划',
        finance_type:'收入',
        decision_type:'计划',
        income_type:'',
        month_one:'',
        month_two:'',
        month_three:'',
        month_four:'',
        month_five:'',
        month_six:'',
        month_seven:'',
        month_eight:'',
        month_nine:'',
        month_ten:'',
        month_eleven:'',
        month_twelve:'',
        year:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        income_type: [{required: true, message: '请选择收入类型'}],
        year: [{required: true, message: '请选择计划年份'}],
        month_one: [
          {required: true, message: '计划内容填写出错，请将一月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将一月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_two: [{required: true, message: '计划内容填写出错，请将二月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将二月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_three: [{required: true, message: '计划内容填写出错，请将三月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将三月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_four: [{required: true, message: '计划内容填写出错，请将四月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将四月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_five: [{required: true, message: '计划内容填写出错，请将五月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将五月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_six: [{required: true, message: '计划内容填写出错，请将六月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将六月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_seven: [{required: true, message: '计划内容填写出错，请将七月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将七月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_eight: [{required: true, message: '计划内容填写出错，请将八月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将八月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_nine: [{required: true, message: '计划内容填写出错，请将九月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将九月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_ten: [{required: true, message: '计划内容填写出错，请将十月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将十月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_eleven: [{required: true, message: '计划内容填写出错，请将十一月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将十一月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
        month_twelve: [{required: true, message: '计划内容填写出错，请将十二月份计划填写完整'},
          {
            validator: (rule, value, callback) => {
              if(/^[0-9]*\.?[0-9]*$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请将十二月份计划填写完整')
              }
            }, trigger: 'blur'
          }
        ],
      },
      monitorpointList:[],
      workPointList:[],
      yearOptions: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getYearOptions();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.state_id) {
        if(this.showType == 'edit' || this.showType == 'detail'||this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              state_id: this.detailData.state_id
            }
            getOperatingStatementListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit'|| this.showType=='paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addOperatingStatement(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'|| this.showType=='paste'){
              this.showLoading();
              modifyOperatingStatement(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=3; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>